// extracted by mini-css-extract-plugin
export var a = "plasmic_minimalist_about_me_page-module--a--fArr7";
export var address = "plasmic_minimalist_about_me_page-module--address--2humo";
export var all = "plasmic_minimalist_about_me_page-module--all--z0Ezw";
export var blockquote = "plasmic_minimalist_about_me_page-module--blockquote--1ekWE";
export var button = "plasmic_minimalist_about_me_page-module--button--s-LDV";
export var code = "plasmic_minimalist_about_me_page-module--code--tc5W4";
export var h1 = "plasmic_minimalist_about_me_page-module--h1--2wzth";
export var h2 = "plasmic_minimalist_about_me_page-module--h2--Qzzwq";
export var h3 = "plasmic_minimalist_about_me_page-module--h3--r6dl+";
export var h4 = "plasmic_minimalist_about_me_page-module--h4--CM4KR";
export var h5 = "plasmic_minimalist_about_me_page-module--h5--oRcCr";
export var h6 = "plasmic_minimalist_about_me_page-module--h6--ocOtL";
export var img = "plasmic_minimalist_about_me_page-module--img--Y3q31";
export var input = "plasmic_minimalist_about_me_page-module--input--FYvOA";
export var li = "plasmic_minimalist_about_me_page-module--li--C3GLz";
export var ol = "plasmic_minimalist_about_me_page-module--ol--IbDin";
export var p = "plasmic_minimalist_about_me_page-module--p--ggMYh";
export var plasmic_default__blockquote = "plasmic_minimalist_about_me_page-module--plasmic_default__blockquote--odPR+";
export var plasmic_default__code = "plasmic_minimalist_about_me_page-module--plasmic_default__code--UccjH";
export var plasmic_default__component_wrapper = "plasmic_minimalist_about_me_page-module--plasmic_default__component_wrapper--MTi7i";
export var plasmic_default__h1 = "plasmic_minimalist_about_me_page-module--plasmic_default__h1--L4+4f";
export var plasmic_default__h2 = "plasmic_minimalist_about_me_page-module--plasmic_default__h2--9PpKS";
export var plasmic_default__h3 = "plasmic_minimalist_about_me_page-module--plasmic_default__h3--jnvNt";
export var plasmic_default__h4 = "plasmic_minimalist_about_me_page-module--plasmic_default__h4--AFnX6";
export var plasmic_default__h5 = "plasmic_minimalist_about_me_page-module--plasmic_default__h5--bSsnA";
export var plasmic_default__h6 = "plasmic_minimalist_about_me_page-module--plasmic_default__h6--gvIad";
export var plasmic_default__inline = "plasmic_minimalist_about_me_page-module--plasmic_default__inline--Nt2L4";
export var plasmic_default__ol = "plasmic_minimalist_about_me_page-module--plasmic_default__ol--1xiQN";
export var plasmic_default__pre = "plasmic_minimalist_about_me_page-module--plasmic_default__pre--TutTe";
export var plasmic_default__ul = "plasmic_minimalist_about_me_page-module--plasmic_default__ul--bqZtr";
export var plasmic_default_styles = "plasmic_minimalist_about_me_page-module--plasmic_default_styles--oFTd9";
export var plasmic_page_wrapper = "plasmic_minimalist_about_me_page-module--plasmic_page_wrapper--rxfwu";
export var plasmic_tokens = "plasmic_minimalist_about_me_page-module--plasmic_tokens--1Fc43";
export var pre = "plasmic_minimalist_about_me_page-module--pre--C6Ykp";
export var root_reset = "plasmic_minimalist_about_me_page-module--root_reset--hx6GW";
export var select = "plasmic_minimalist_about_me_page-module--select--hz3Eo";
export var span = "plasmic_minimalist_about_me_page-module--span--cii1t";
export var textarea = "plasmic_minimalist_about_me_page-module--textarea--IYo3a";
export var ul = "plasmic_minimalist_about_me_page-module--ul--g25e3";